import _types from "./types";
var exports = {};
var types = _types;

exports.wordBoundary = function () {
  return {
    type: types.POSITION,
    value: "b"
  };
};

exports.nonWordBoundary = function () {
  return {
    type: types.POSITION,
    value: "B"
  };
};

exports.begin = function () {
  return {
    type: types.POSITION,
    value: "^"
  };
};

exports.end = function () {
  return {
    type: types.POSITION,
    value: "$"
  };
};

export default exports;